.container {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 0 1rem;
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid grey; */
}

.button {
    border: 0;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    line-height: 0;
}

.button:hover .icon {
    fill: rgb(51, 49, 49);
}

.button:hover .icon2 {
    fill: rgb(51, 49, 49);
}

.icon {
    cursor: pointer;
    fill: #6b6f7a;
    width: 28px;
    height: auto;
}

.icon2 {
    cursor: pointer;
    fill: #6b6f7a;
    width: 22px;
    height: auto;
}

.link {
    position: absolute;
    top: 14px;
    left: 55px;
    font-size: 18px;
    line-height: 22px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    background-color: #fff;
    padding-right: 20px;
    padding-left: 5px;
    user-select: none;
}

.iconSelected {
    cursor: pointer;
    fill: #3d6df0;
    width: 22px;
    height: auto;
}